import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box } from '@chakra-ui/core'

const URL = `${process.env.GATSBY_IFRAME_PROTOCOL}://${process.env.GATSBY_IFRAME_URL}`

const SecondPage = () => {
	const [queryString, setQueryString] = React.useState('')
	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			setQueryString(window.location.search)
		}
	}, [])

	return (
		<Layout>
			<SEO title='Assinatura' />
			{/* <HeaderIframe /> */}
			{/* <Header /> */}

			<Box
				as='iframe'
				w='100%'
				h='100vh'
				pos='fixed'
				id='iframe-assinatura-digital'
				frameBorder='0'
				title='Iframe de Assinatura Digital'
				src={`${URL}${queryString}`}
				allowFullScreen
			/>
		</Layout>
	)
}

export default SecondPage
